@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
}

body {
  background-color: rgb(0, 177, 181);
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  overflow: auto;
}

html {
  height: 100%;
}
/*
** CÓDIGOS DAS TELAS DE TELAS LOGIN E CADASTRO 
**/

#root {
  height: 100%;
  width: 100%;
}

.pagina-login {
  /* Full height */
  /* height: 100%; */
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
}

.pagina-config {
  background-color: #d1d1d1;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.pagina-login-cinza {
  background-color: #d1d1d1;
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
}

.personagem {
  background-image: url('../assets/imagens/cor-00.png');
}

.container-login {
  overflow: auto;
  min-height: 1000px;
  text-align: center;
  background: #fff;
  display: block;
  /* margin: 5% auto; */
  padding: 40px;
  width: 850px;
  min-height: 520px;
  text-align: center;
  background: #fff;
  border: 2px solid #d1d1d1;
  border-radius: 20px;
  position: absolute;
}

.container-login-aulaExperimental {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: auto;
  min-height: 1000px;
  text-align: center;
  background: #fff;
  display: block;
  margin: 5% auto;
  padding: 40px;
  width: 850px;
  min-height: 520px;
  text-align: center;
  background: #fff;
  border: 2px solid #d1d1d1;
  border-radius: 20px;
  position: absolute;
}

.form-images {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 500px;
  margin-bottom: 40px;
  justify-content: center;
}

.container-aulaExperimental {
  display: flex;
  justify-content: center;
}

.container-login-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;
  margin: 5% auto;
  padding: 40px;
  width: 850px;

  border: 2px solid #d1d1d1;
  border-radius: 20px;
  min-height: 700px;
}

@media (max-width: 800px) {
  body {
    background-color: white;
   
  }
}

@media (max-width: 420px) {
  .container-login-aulaExperimental {
    max-width: 320px;
  }

  .container-login {
    max-width: 320px;
  }

  .form-login-signup input {
    display: flex;
    flex-direction: column;
  }

  .form-login input {
    width: 100%;
  }

  .form-login input[type='email'] {
    max-width: 295px;
  }

  .form-login input[type='phone'] {
    max-width: 295px;
  }

  .form-login > div {
    display: flex;
    flex-direction: column;
  }

  .container-aulaExperimental {
    margin: 0 auto;
  }

  .pagina-login {
    max-width: 320px;
    margin: 0 auto;
  }

  body {
    background-image: none;
    background-color: white;
  }
}

.container-login-1 {
  margin: 5% auto;
  min-height: 520px;
  text-align: center;
  background: #fff;
  border: 2px solid #d1d1d1;
  border-radius: 20px;
  width: 850px;
}

.text-container {
  margin: 18% auto;
  max-height: 260px;
}

.container-config {
  overflow: auto;
  min-height: 1000px;
  text-align: center;
  background: #fff;
  display: block;
  margin: 5% auto;
  padding: 40px;
  width: 850px;
  min-height: 100vh;
  text-align: center;
  border: 2px solid #d1d1d1;
  border-radius: 20px;
  position: absolute;
}

.container-login h1 {
  font-size: 2em;
  /* text-transform: uppercase; */
}

.container-login span.obs {
  font-size: 0.8em;
}

.container-login img.logo-login {
  margin-bottom: 18px;
}

div.termos {
  margin-bottom: 18px;
  padding: 0 10px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;
  border: 2px solid #d1d1d1;
  border-radius: 20px 10px 10px 20px;
}

div.termos::-webkit-scrollbar {
  width: 12px;
}

div.termos::-webkit-scrollbar-track {
  border-radius: 10px;
}

div.termos::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}

/* FORMULÁRIOS */

.form-login {
  margin-bottom: 10px;
}

.form-login .box-password {
  margin: 0 auto;
  display: inline-block;
}

.form-login input[type='text'],
.form-login input[type='password'],
.form-login input[type='email'],
.form-login input[type='phone'] {
  display: block;
  margin: 0 auto 15px;
  padding: 12px;
  width: 600px;
  color: #010100;
  /* text-transform: uppercase; */
  border: 1px solid darkgray;
  border-radius: 6px;
}

.form-login input[type='text']::placeholder,
.form-login input[type='password']::placeholder {
  font-size: 1em;
  font-style: italic;
}

.form-login input[type='text']:focus,
.form-login input[type='password']:focus {
  outline: #008fee;
}

/* FORMULÁRIOS – IMAGENS */

.form-login input[type='image'],
.form-login img {
  margin: 0 8px 25px;
  max-width: 330px;
}

.form-login input[type='image']:hover,
.form-login img:hover {
  transform: rotate(-3deg);
  -ms-transform: rotate(-3deg); /* IE 9 */
}

/* BOTÕES */

.footer-nav {
  /* position: absolute; */
  bottom: 30px;
  width: 850px;
  /* min-height: 45px; */
}

.footer-nav .ali-left {
  float: left;
  display: block;
  margin-right: 18px;
}

.footer-nav .ali-right {
  float: right;
  display: block;
  margin-left: 18px;
}

.btn-padrao {
  margin: 0 auto;
  padding: 10px 35px 9px;
  font-size: 1em;
  font-weight: 500;
  color: #fff;
  /* text-transform: uppercase; */
  text-decoration: none;
  border: 0 solid;
  border-radius: 6px;
  background: #008fee;
  cursor: pointer;
}

.btn-padrao:hover {
  background: #006db5;
}

.btn-padrao-lp:hover {
  background: #006db5;
}

.btn-padrao-lp {
  margin: 0 auto;
  padding: 12px 50px 12px;
  font-size: 1em;
  font-weight: 500;
  line-height: 18px;
  background-color: #008fee;
  border-bottom: 4px solid #005089;
  border-left: 0px solid transparent;
  border-radius: 10px;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  color: #ffffff;
  font-family: inherit;
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  /* padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px; */
  width: auto;
  display: inline-block;
}

.btn-link {
  padding: 10px 0 9px;
  font-size: 1em;
  font-weight: 500;
  color: #008fee;
  /* text-transform: uppercase; */
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.checkbox-container {
  width: 650px;
  margin: 0 auto 15px;
  text-align: left;
  font-size: 18px;
}

.checkbox-container a {
  color: #008fee;
}

.btn-link:hover {
  color: #02c2da;
}

.btn-left {
  margin-right: 450px;
}

.input-duplo {
  max-width: 640px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
}

.input-duplo input {
  float: left;
  margin: 0 6px 15px !important;
  max-width: 290px;
}

#btn-olho {
  margin-top: -55px;
  float: right;
  margin-right: 40px;
  width: 10px;
  cursor: pointer;
}

#btn-olho i {
  font-size: 30px;
  color: rgb(128, 128, 128);
}

#btn-olho:focus {
  outline: none;
}

/* AJUSTES PARA CELULARES */

@media (max-width: 850px) {
  body.pagina-login {
    background-image: none;
  }

  .container-login {
    margin: 0 auto;
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .footer-nav {
    background-color: #fff;
  }

  .container-login-signup {
    margin: 0 auto;
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  img.logo-login {
    margin-top: 10px;
  }

  .container-login h1 {
    font-size: 1.8em;
  }

  .form-login {
    width: 100%;
  }

  .form-login input[type='text'],
  .form-login input[type='password'],
  .form-login .box-password input[type='password'] {
    display: block;
    margin: 0 auto 15px;
    padding: 12px 0;
    width: 100%;
    text-align: center;
  }

  .form-login .box-password {
    margin: 0 auto;
    display: inline-block;
  }

  .footer-nav {
    position: relative;
    bottom: 0;
    margin-top: 70px;
    width: 100%;
  }

  .footer-nav .ali-left,
  .footer-nav .ali-right {
    float: none;
    margin: 0 0 12px;
  }

  .btn-padrao {
    width: 100%;
    padding: 10px 0 9px;
    font-size: 1em;
    border-radius: 10px;
  }

  .box-password {
    width: 100%;
  }

  .footer-nav .btn-link {
    width: 100%;
    padding: 10px 0 9px;
    font-size: 1em;
    font-weight: 500;
    color: #008fee;
    /* text-transform: uppercase; */
    text-decoration: none;
  }
}

/* CUSTOMIZADO REACT */

.message {
  color: white;
}
