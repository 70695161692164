@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Códigos aula Experimental */

/*
** CÓDIGOS DAS TELAS ÁREA DO USUÁRIO 
**/



.image:hover {
  filter: saturate(2);
}

#header {
  background: #fcfcfc;
}

#header .header-personagem {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 170px;
  height: 170px;
  /* top: 42px; */
  left: 20px;
  padding: 15px 10px 0 10px;
  border: 0 solid;
  border-radius: 100%;
}

#header .header-nome {
  position: absolute;
  top: 120px;
  left: 220px;
  font-family: 'Bungee', sans-serif;
  font-size: 25px;
}

#header .header-som {
  padding-right: 140px;
  margin-top: -100px;
  float: right;
  cursor: pointer;
}

#header .header-personagem:hover {
  transform: rotate(-6deg);
  -ms-transform: rotate(-6deg); /* IE 9 */
}

#header .header-personagem img {
  display: block;
  position: absolute;
  bottom: 0;
  left: 10px;
}

#header .header-back {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 248px;
  height: 71px;
  top: 42px;
  left: 20px;
  padding: 10px;
  background-image: url('../assets/imagens/back.png');
  background-position: center;
  background-repeat: no-repeat;
  border: 0 solid;
  border-radius: 10px;
  cursor: pointer;
}

#header .header-back:hover {
  transform: rotate(-3deg);
  -ms-transform: rotate(-3deg); /* IE 9 */
}

#header nav.nav-unidades {
  display: table;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

#header nav.nav-unidades a {
  display: inline-block;
  width: 189px;
  height: 193px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#header nav.nav-unidades a:hover {
  filter: saturate(2);
  transform: rotate(-5deg);
  -ms-transform: rotate(-5deg); /* IE 9 */
}

#header nav.nav-unidades a.nav-01 {
  background-image: url('../assets/imagens/nav-01.png');
}
#header nav.nav-unidades a.nav-02 {
  background-image: url('../assets/imagens/nav-02.png');
}
#header nav.nav-unidades a.nav-03 {
  background-image: url('../assets/imagens/nav-03.png');
}
#header nav.nav-unidades a.nav-04 {
  background-image: url('../assets/imagens/nav-04.png');
}

#header .logo-header {
  background-image: url('../assets/imagens/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 120px;
  margin-top: 25px;
  margin-bottom: 25px;
}

#header nav.nav-unidades h1 {
  display: block;
  margin: 80px auto 0;
  max-width: 800px;
  font-family: 'Bungee', sans-serif;
  font-size: 45px;
  line-height: 1em;
  letter-spacing: 1px;
  text-align: center;
}

#header .btn-gaveta {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 42px;
  right: 20px;
  width: 111px;
  height: 111px;
  background-image: url('../assets/imagens/bloqueado-01.png');
}

@media (max-width: 1170px) {
  #header .header-personagem {
    width: 150px;
    height: 150px;
    /* top: 32px; */
    left: 10px;
  }

  #header .header-personagem img {
    left: 0;
  }

  #header .header-nome {
    position: absolute;
    top: 130px;
    left: 180px;
    font-size: 20px;
  }

  #header .header-back {
    width: 150px;
    height: 60px;
    top: 32px;
    left: 10px;
    padding: 8px;
    background-size: 90%;
    cursor: pointer;
  }

  #header nav.nav-unidades {
    padding-left: 35px;
  }

  #header nav.nav-unidades a {
    width: 165px;
    height: 170px;
  }

  #header nav.nav-unidades h1 {
    margin: 0px auto 0;
    font-size: 40px;
  }

  #header .btn-gaveta {
    top: 32px;
    right: 10px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.pagina-in {
  background-color: #d1d1d1;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  position: relative;
  overflow: auto;
}

.button {
  cursor: pointer;
}

/* CONTAINERS */

.container-unidade,
.container-aula,
.container-atividades {
  padding-bottom: 80px;
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  overflow: auto;
}

@media (max-width: 420px) {
  .container-atividades {
    padding-bottom: 0px;
  }
}

.container-atividades {
  display: flex;
  flex-direction: column;
}

.itens {
  display: block;
  margin: 0 auto;
  max-width: 1430px;
  text-align: center;
}

.container-unidade .itens li {
  display: inline-table;
  margin: 75px 20px 0;
  width: 400px;
  cursor: pointer;
}

.container-aula .itens li {
  display: inline-table;
  margin: 75px 20px 0;
  width: 300px;
  cursor: pointer;
}

.itens li:hover {
  filter: saturate(2);
}

.itens li .badge {
  position: relative;
  display: block;
  margin: 0 auto -35px;
  z-index: 2;
  width: 40%;
}

.container-aula .itens li .badge {
  width: 50%;
  max-width: 200px;
}

.itens li .badge-border {
  margin: 0;
  width: 350px;
  height: 480px;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  z-index: 1;
  border: solid;
  border-radius: 15px;
  border-width: 7px;
}

.aula {
  margin: 0;
  height: 250px;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  z-index: 1;
  border: solid;
  border-radius: 15px;
  border-width: 7px;
}

.itens li h3,
.itens li h4 {
  margin: 10px 0 0;
  font-family: 'Bungee', sans-serif;
  font-size: 25px;
  line-height: 1em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

.grupo-unidades {
  background-color: #fff;
  opacity: 0.8;
  display: inline-block;
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.texto-grupo-unidades {
  font-family: 'Bungee', sans-serif;
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  color: #000;
}

.disponibilidade-unidade {
  background-color: #fff;
  color: #000;
  opacity: 0.7;
  display: inline-block;
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.texto-disponibilidade-unidade {
  font-family: 'Bungee', sans-serif;
  font-size: 18px !important;
  line-height: 1em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  color: #000 !important;
}

@media (max-width: 1390px) {
  .itens li .badge {
    width: 60%;
  }
  .itens li {
    width: 300px;
  }
}

.seta-01,
.seta-02 {
  position: absolute;
  display: block;
  top: 50%;
  width: 233px;
  height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.seta-01:hover,
.seta-02:hover {
  top: 49%;
}

.seta-01 {
  left: 10px;
  background-image: url('../assets/imagens/seta-esquerda.png');
}

.seta-02 {
  right: 10px;
  background-image: url('../assets/imagens/seta-direita.png');
}

.seta-01-mobile {
  left: 10px;
  background-image: url('../assets/imagens/seta-esquerda.png');
}

.seta-02-mobile {
  right: 10px;
  background-image: url('../assets/imagens/seta-direita.png');
}

.seta-01-mobile,
.seta-02-mobile {
  position: absolute;
  display: block;
  top: 92%;
  width: 50px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card-fim-aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 580px;
}

@media (max-width: 420px) {
  .card-fim-aula {
    height: 320px;
  }
}

.atividades {
  display: block;
  margin: 75px auto;
  width: 100%;
  max-width: 1140px;
  background: #fff;
  border: 20px solid #fff;
  border-radius: 15px;
}

.tutorial {
  display: block;
  margin: 75px auto;
  width: 100%;
  max-width: 1140px;
  background: #fff;
  border: 20px solid #fff;
  border-radius: 15px;
}

.atividades iframe {
  width: 100%;
  min-height: 700px;
}

@media (max-width: 1670px) {
  .seta-01,
  .seta-02 {
    width: 134px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .seta-01 {
    left: 5px;
  }

  .seta-02 {
    right: 5px;
  }

  .atividades {
    max-width: 1000px;
  }

  .atividades iframe {
    min-height: 600px;
  }
}

@media (max-width: 1260px) {
  .atividades {
    max-width: 650px;
  }

  .atividades iframe {
    min-height: 500px;
  }
}

/* GAVETA DE PROTEÇÃO */

#gaveta {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(20, 209, 200, 0.6);
  position: fixed;
  /* display: none; */
  float: left;
  z-index: 3;
}

#gaveta:target {
  display: block;
  z-index: 3;
}

#gaveta:target ~ .box {
  display: block;
  z-index: 4;
}

#gaveta:target ~ .gaveta-popup {
  display: block;
}

#gaveta .gaveta-bg-container {
  width: 65%;
  float: left;
  height: 100vh;
}

#gaveta .gaveta-bg {
  width: 436px;
  height: 614px;
  position: absolute;
  margin-top: 10%;
  margin-left: -400px;
  left: 50%;
  background-image: url('../assets/imagens/pais.png');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 4;
}

#gaveta .gaveta-popup {
  float: right;
  width: 35%;
  height: 100vh;
  text-align: center;
  background: #ff9a00;
}

#gaveta .gaveta-cont {
  margin-top: 30px;
  padding-top: 140px;
  background-image: url('../assets/imagens/bloqueado-02.png');
  background-repeat: no-repeat;
  background-position: top center;
}

#gaveta h2 {
  font-family: 'Bungee', sans-serif;
  font-size: 40px;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}

#gaveta p {
  font-family: 'Roboto', sans-serif;
  font-size: 35px;
  font-weight: 300;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}

#gaveta .chave {
  margin-top: 40px;
  font-family: 'Bungee', sans-serif;
  font-size: 70px;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}

#gaveta .chave input[type='text'] {
  width: 80px;
  height: 80px;
  padding: 5px;
  text-align: center;
  border: 5px solid #fff;
  font-family: 'Bungee', sans-serif;
  font-size: 70px;
  line-height: 1em;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
}

#gaveta .chave input[type='text']::placeholder {
  font-family: 'Bungee', sans-serif;
  font-size: 80px;
  line-height: 1em;
  color: #fff;
}

#gaveta input[type='button'] {
  margin-top: 40px;
  padding: 25px 30px;
  border: 5px solid #fff;
  border-radius: 15px;
  font-family: 'Bungee', sans-serif;
  font-size: 50px;
  line-height: 1em;
  color: #fff;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}

#gaveta input:focus {
  outline: none;
}

#gaveta .btn-ajuda {
  margin-top: 40px;
  padding: 25px 30px;
  font-family: 'Bungee', sans-serif;
  font-size: 30px;
  line-height: 1em;
  color: #fff;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}

#gaveta .btn-ajuda:focus {
  outline: none;
}

/* AJUSTES PARA CELULARES */

@media (max-width: 960px) {
  #header .header-personagem {
    width: 60%;
    height: 150px;
    top: 0;
    left: 0;
    padding: 0;
    border-radius: 0;
  }

  #header .header-nome {
    display: none;
  }

  #header .header-back {
    width: 60%;
    height: 150px;
    top: 0;
    left: 0;
    padding: 0;
    border-radius: 0;
    cursor: pointer;
  }

  #header .header-som {
    position: absolute;
    width: 30%;
    height: 110px;
    padding: 40px 0 0 0;
    margin-right: 0px;
    margin-top: 0px;
    top: 0;
    left: 40%;
    float: none;
    background: #e0e2e1;
    text-align: center;
  }

  #header .header-personagem:hover,
  #header .header-back:hover {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg); /* IE 9 */
    cursor: pointer;
  }

  #header nav.nav-unidades {
    margin: 150px auto 0;
    padding-left: 10px;
  }

  #header nav.nav-unidades a {
    display: inline-block;
    width: 145px;
    height: 145px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  #header .btn-gaveta {
    cursor: pointer;
    top: 0;
    right: 0;
    width: 40%;
    height: 150px;
    background-image: url('../assets/imagens/bloqueado-02.png');
    background-color: #cecece;
  }

  .itens {
    padding: 0;
  }

  .itens li {
    display: inline-table;
    margin: 50px 0 0;
    width: 90% !important;
    cursor: pointer;
  }

  .itens li:hover {
    filter: saturate(2);
  }

  

  .itens li .badge {
    position: relative;
    display: block;
    margin: 0 auto -35px;
    z-index: 2;
  }

  .itens li .badge-border {
    margin: 0;
    width: 100% !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    z-index: 1;
  }

  .itens li h3,
  .itens li h4 {
    margin: 10px 0 0;
    font-family: 'Bungee', sans-serif;
    font-size: 25px;
    line-height: 1em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
  }

  .seta-01,
  .seta-02 {
    top: 920px;
  }

  .seta-01:hover,
  .seta-02:hover {
    top: 920px;
  }

  .seta-01 {
    left: 0;
  }

  .seta-02 {
    right: 0;
  }

  .atividades {
    width: 90%;
    border: 10px solid #fff;
  }

  .atividades iframe {
    min-height: 500px;
  }

  #gaveta {
    position: absolute;
    float: none;
    width: 100%;
  }
  #gaveta .gaveta-bg {
    display: none;
  }
  #gaveta .gaveta-popup {
    width: 100%;
    height: 1200px;
    margin: 0;
    position: absolute;
    float: none;
  }
}

/* CUSTOM */

.img-edit-perfil {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.img-pagamento {
  width: 350px;
  margin: 15px;
}

.img-pagamento-garantia {
  width: 160px;
  margin: 15px;
}

.form-generico {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: -60px;
}
.page_Error {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  padding-top: 70px;
  border-radius: 15px;
  background-color: white;
  flex-direction: column;
}
.container_Page_Error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.container_Error {
  /* background-color: red; */
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: auto;
}
.title_Error {
  display: flex;
  flex-direction: column;
  padding-left: 200px;
  padding-right: 200px;
  text-align: justify;
  /* background-color:red; */
  font-size: 27px;
}
.error_Link {
  align-self: flex-end;
  margin: 0px;
  margin-bottom: 10px;
  margin-right: 10px;
  /* background-color: red;; */
  text-decoration: underline;
  color: #006db5;
}
.error_Link:hover {
  cursor: pointer;
}
.error_Stack {
  background-color: #dddd;
  padding: 14px;
  width: 60%;
  border-radius: 15px;
  overflow: hidden;
}
.logo_Mobile {
  height: 70px;
  margin-top: -40px;
}
.title_Error_Mobile {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  text-align: justify;
  /* background-color:red; */
  font-size: 16px;
}

.error_Stack_Mobile {
  background-color: #dddd;
  padding: 14px;
  width: 80%;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}
.error_Text {
  font-size: 14px;
}

.error_Link_Mobile {
  align-self: flex-end;
  margin: 0px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-top: 5px;
  /* background-color: red;; */
  text-decoration: underline;
  color: #006db5;
  font-size: 14px;
}
