#gavetaMobile {
  /* position: absolute; */
  /* float: none; */
  width: 100%;
  /* height: 100%; */
  top: 0;
  left: 0;
  background-color: rgba(20, 209, 200, 0.6);
  position: fixed;
  /* display: none; */
  float: left;
  /* z-index: 3; */
}

#gavetaMobile:target {
  display: block;
  z-index: 3;
}

#gavetaMobile:target ~ .box {
  display: block;
  z-index: 4;
}

#gavetaMobile:target ~ .gavetaMobile-popup {
  display: block;
}

#gavetaMobile .gavetaMobile-bg-container {
  width: 65%;
  float: left;
  /* height: 100vh; */
}

#gavetaMobile-bg {
  width: 436px;
  height: 614px;
  position: absolute;
  margin-top: 10%;
  margin-left: -400px;
  left: 50%;
  background-image: url('../assets/imagens/pais.png');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 4;
  display: none;

}

/* #gavetaMobile .gavetaMobile-popup {
  float: right;
  width: 35%;
  height: 100vh;
  text-align: center;
  background: #ff9a00;
} */

#gavetaMobile .gavetaMobile-cont {
  margin-top: 30px;
  padding-top: 140px;
  background-image: url('../assets/imagens/bloqueado-02.png');
  background-repeat: no-repeat;
  background-position: top center;
}

#gavetaMobileh2 {
  font-family: 'Bungee', sans-serif;
  font-size: 25px;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}



#gavetaMobileP {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}

#gavetaMobileChave {
  margin-top: 40px;
  font-family: 'Bungee', sans-serif;
  font-size: 55px;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
}

#gavetaMobileChave input[type='text'] {
  width: 80px;
  height: 80px;
  padding: 5px;
  text-align: center;
  border: 5px solid #fff;
  font-family: 'Bungee', sans-serif;
  font-size: 55px;
  line-height: 1em;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
}

#gavetaMobileChave input[type='text']::placeholder {
  font-family: 'Bungee', sans-serif;
  font-size: 55px;
  line-height: 1em;
  color: #fff;
}

#gavetaMobile input[type='button'] {
  margin-top: 40px;
  padding: 25px 30px;
  border: 5px solid #fff;
  border-radius: 15px;
  font-family: 'Bungee', sans-serif;
  font-size: 40px;
  line-height: 1em;
  color: #fff;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}

#gavetaMobile input:focus {
  outline: none;
}

#gavetaMobile .btn-ajuda {
  margin-top: 40px;
  padding: 25px 30px;
  font-family: 'Bungee', sans-serif;
  font-size: 30px;
  line-height: 1em;
  color: #fff;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}

#gavetaMobile .btn-ajuda:focus {
  outline: none;
}


/* Mobile */

#header .btn-gaveta {
  cursor: pointer;
  top: 0;
  right: 0;
  width: 40%;
  height: 150px;
  background-image: url('../assets/imagens/bloqueado-02.png');
  background-color: #cecece;
}

#gavetaMobile {
  position: absolute;
  float: none;
  width: 100%;
}



#gavetaMobile .gavetaMobile-popup {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  float: none;

  /* float: right; */
  /* width: 35%; */
  /* height: 100vh; */
  text-align: center;
  background: #ff9a00;
}