/*      Classes Globais        */
/* 
    * {
        margin: 0;
        padding: 0;
    } */

.box-pontuacao {
  display: grid;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  text-align: center;
  margin: 20px auto;
}

.box-topo {
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  /* background: rgb(218, 249, 253); */
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
}

.box-palavras {
  display: flex;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  /* background: #75dfeb; */
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  text-align: center;
  margin: 20px auto;
  /* margin: 0 auto; */
  flex-wrap: wrap;
  justify-content: center;
}

.palavra-item {
  background: #f5880c;
  border-radius: 15px;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  margin: 15px;
}

.palavra-item-1 {
  background: #f5880c;
  border-radius: 15px;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  margin: 15px;
}

.box-grupo {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  display: flex;
  background: #f5880c;
  font-size: 32px;
  padding: 15px;
  border-radius: 15px;
  /* background: #75dfeb; */
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  text-align: center;
  /* margin: 0 auto; */
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.box-grupo-input {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding: 10px;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background: #ffffff;
  margin: 2px;
}

.box-grupo-input input {
  width: 275px;
  border-style: none;
}

.palavra-item-marcada {
  background: green;
  text-decoration: line-through;
}

.item-box-topo-1 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.item-box-topo-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.box-botao-centro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.box-botao-centro2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  background: #75dfeb;
  border-radius: 15px;
  padding: 15px;
  width: 86%;
}

.p-text {
  /* margin-right: 20%; */
  /* padding: 20px; */
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
}

.p-text-input {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.p-text-input:focus {
  outline: none;
}

.p-text-input-2 {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px;
}

.p-text-input-2:focus {
  outline: none;
}

.arrastar-recpiente-texto {
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #000000;
}

.btn-repetir {
  min-width: 25px;
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.btn-repetir:hover {
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.btn-concluir {
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
}

.btn-download {
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: lightcoral;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.download-container {
  display: flex;
  place-content: center;
  margin: 88px 0;
}

.btn-concluir-2 {
  display: flex;
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  max-width: 160px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.btn-concluir:hover {
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.fas.fa-check {
  border-radius: 200px;
  background: #fff;
  padding: 8px;
  color: #5be389;
}

.fas.fa-undo-alt {
  border-radius: 200px;
  background: #fff;
  padding: 8px;
  color: #91dbe4;
}

.fas.fa-chevron-right {
  border-radius: 200px;
  background: #fff;
  padding: 8px;
  color: #91dbe4;
}

.icone-desenho {
  border-radius: 200px;
  padding: 8px;
  color: #91dbe4;
  font-size: 35px;
}

/*  Tela 1.html  */

.tl1-box-1 {
  padding: 15px;
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 970px;
  /* height: 430px; */
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl1-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  /* height: 430px; */
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl1-figura-0 {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  max-width: 450px;
  overflow: hidden;
}

.tl1-figura-correta-botao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* AQUI ESTÃO AS ALTERAÇÕES PARA A PÁGINA AULA EXPERIMENTAL */

.tl1-figura-0 img {
  height: 350px;
}

.tl1-figura-0 {
  height: 350px;
}

.tl1-figura-correta {
  max-width: 460px;
  max-height: 380px;
}

.tl1-figura-correta img {
  /* height: 350px; */
  max-width: 450px;
  max-height: 360px;
}

.tl1-figura-errada {
  max-width: 460px;
  max-height: 380px;
}

.tl1-figura-errada img {
  max-width: 450px;
  max-height: 360px;
}

@media (max-width: 420px) {
  .tl1-figura-0 {
    max-width: 220px;
    max-height: 200px;
  }

  .tl1-figura-0 img {
    width: 200px;
    height: 100%;
  }

  .tl1-figura-correta {
    max-width: 220px;
    max-height: 200px;
  }

  .tl1-figura-errada img {
    width: 200px;
    height: 100%;
  }

  .tl1-figura-errada img {
    width: 200px;
    height: 100%;
  }

  .tl1-figura-errada video {
    width: 200px;
    height: 100%;
  }

  .tl1-box-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tl1-figura-confirmacao {
    max-width: 300px;
  }

  .tl1-figura-correta-botao {
    display: flex;
    flex-direction: column;
  }

  .tl1-figura-confirmacao {
    height: 150px;
  }
}

.tl1-figura-1 {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.tl1-figura-errada {
  display: flex;
  /* padding: 10px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #ff0000;
  border-radius: 15px;
  background: #ffffff;
  /* max-width: 240px;
        max-height: 182px; */
  overflow: hidden;
  /* background: rgb(239 165 202); */
  text-align: center;
  cursor: pointer;
}

.tl2-figura-errada {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #ff0000;
  border-radius: 15px;
  background: #ffffff;
  overflow: hidden;
  /* background: rgb(239 165 202); */
  text-align: center;
  cursor: pointer;
}

.tl1-figura-correta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  border: 10px solid #1dd138;
  border-radius: 15px;
  background: #ffffff;
  /* max-width: 250px; */
  overflow: hidden;
  /* background: rgb(144 197 153); */
  text-align: center;
  cursor: pointer;
}

.tl2-figura-correta {
  /* display: flex;
        flex-direction: row;
        justify-content: center; */
  padding: 10px;
  flex-direction: column;
  align-items: center;
  border: 10px solid #1dd138;
  border-radius: 15px;
  background: #ffffff;
  overflow: hidden;
  /* background: rgb(144 197 153); */
  text-align: center;
  cursor: pointer;
}

.tl1-figura-confirmacao {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #03c2fc;
  text-align: center;
  cursor: pointer;
}

/*  Tela 2.html  */

.tl2-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 430px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl2-box-2-2colunas {
  grid-template-columns: auto auto;
}

.tl2-box-2-3colunas {
  grid-template-columns: auto auto auto;
}

.tl2-figura-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(239 165 202);
  cursor: pointer;
}

.tl2-figura-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(249 205 141);
  cursor: pointer;
}

.tl2-figura-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(208 224 157);
  cursor: pointer;
}

.tl2-figura-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(170 145 192);
  cursor: pointer;
}

/*  Tela 3.html  */

.tl3-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  /* height: 430px; */
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl3-figura-0 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
}

.tl3-figura-0:hover {
  border: 10px solid #75dfeb;
}

.tl3-figura-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(203, 253, 145);
  text-align: center;
  cursor: pointer;
}

.tl3-figura-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  text-align: center;
  cursor: pointer;
}

.tl3-figura-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(203, 253, 145);
  text-align: center;
  cursor: pointer;
}

.tl3-figura-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  text-align: center;
  cursor: pointer;
}

.tl3-figura-confirmacao {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px;
  margin: 10px;
  background: #03c2fc;
  text-align: center;
  height: 180px;
}

.tl3-text-fig {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #757575;
}

.tl3-text-fig-0 {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #757575;
}

/*  Tela 4.html  */

.tl4-box-1 {
  grid-gap: 20px;
  grid-template-columns: 250px 715px;
  display: grid;
  width: 985px;
  height: 430px;
  margin: 20px auto;
  border-radius: 15px;
  background: rgb(255, 255, 255);
}

.tl4-box-figuras {
  grid-gap: 20px;
  grid-template-columns: auto auto;
  display: grid;
  width: 692.6px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 15px;
  background: rgb(238, 155, 60);
}

.tl4-figura-lateral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: rgb(197 151 195);
  cursor: pointer;
}

.tl4-figura-2 {
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  cursor: pointer;
}

.tl4-figura-3 {
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(203, 253, 145);
  cursor: pointer;
}

.tl4-figura-4 {
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(203, 253, 145);
  cursor: pointer;
}

.tl4-figura-5 {
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  cursor: pointer;
}

/*  Tela 5.html */

.tl5-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 430px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl5-figura-1 {
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  padding: 10px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
  width: 600px;
}

.tl5-figura-3 {
  border: 10px solid #03c2fc;
  border-radius: 15px;
  background: #03c2fc;
  cursor: pointer;
  padding-left: 15px;
}

.tl5-figura-4 {
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  cursor: pointer;
}

/*  Tela 6.html  */

.tl6-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 560px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
}

.tl6-box-3 {
  grid-row-gap: 12px;
  grid-template-columns: auto;
  display: grid;
}

.tl6-figura-1 {
  padding: 10px;
  border-radius: 15px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  background-size: contain;
}

.tl6-figura-2 {
  grid-gap: 13px;
  grid-template-columns: 95px 95px;
  display: grid;
  margin: 0 auto;
  padding: 10px;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(203, 253, 145);
  cursor: pointer;
}

.tl6-figura-3 {
  grid-gap: 13px;
  grid-template-columns: 40px 40px 40px 40px;
  display: grid;
  margin: 0 auto;
  padding: 10px;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  cursor: pointer;
}

.tl6-figura-interior-1 {
  border-radius: 15px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tl6-figura-interior-2 {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  min-height: 20px;
}

.box-quebra-cabeca {
  grid-template-columns: auto auto auto auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 750px;
  height: 750px;
  background: rgb(255, 255, 255);
}

/*  Tela 7.html  */

.tl7-box-2 {
  grid-template-columns: auto auto auto auto;
  display: grid;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.tl7-box-3 {
  grid-template-columns: auto auto auto auto auto auto auto auto;
  display: grid;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.tl7-box-4 {
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
  display: grid;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.box-imagem-bg {
  grid-template-columns: auto auto auto auto;
  display: grid;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.tl7-box-confirmacao {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 524px;
  height: 524px;
  text-align: center;
  /* max-width: 700px; */
  /* height: 600px; */
  background: rgb(3, 255, 255, 0.8);
  /* background: #03c2fc; */
}

/* .tl7-figura-1 {
        background: rgb(203, 253, 145);
        cursor: pointer;
    } */

.peca-quebra-cabeca {
  background: rgb(203, 253, 145);
  cursor: pointer;
}
/*     
    .peca-quebra-cabeca:hover {
        opacity: 0;
        cursor: pointer;
    } */

.quebra-cabeca-peca-selecionada {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: rgba(228, 231, 31, 0.5);
  color: white;
}

.tl7-figura-1:hover {
  opacity: 0;
  cursor: pointer;
}

/*  Tela 8.html  */

.tl8-box-2 {
  /* height: 600px; */
  background: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tl8-figura-1 {
  border-radius: 10px;
  background: rgb(255, 252, 54);
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  height: 225px;
  width: 225px;
  margin: 10px;
}

.tl8-figura-1:hover {
  opacity: 0;
  cursor: pointer;
}

.tl8-figura-2 {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 225px;
  width: 225px;
  margin: 10px;
}

.arrastar-recipiente {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

/*  Tela 9.html  */

.tl9-box-2 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 250px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
  background: rgb(225 188 139);
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.548);
}

.tl9-box-3 {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 140px;
  border-radius: 15px;
}

.tl9-figura-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
  background: rgb(155, 124, 78);
  box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.541);
}

.tl9-figura-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(225 188 139);
}

.tl9-figura-letra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  background: rgb(225 188 139);
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.548);
  cursor: pointer;
}

.tl9-p-letra {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
}

.caixa-texto-confirmacao {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 80px;
  border-radius: 15px;
  background: #e9bb3f;
}

.caixa-texto-confirmcao-input {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background: #ffffff;
  min-width: 300px;
}

.caixa-texto-btn-confirmar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.escrever-caixa-texto-confirmacao {
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 970px;
  height: 80px;
  border-radius: 15px;
  background: #e9bb3f;
}

.desenho-background {
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 690px;
  height: 565px;
  pointer-events: none;
}

/*  Modal  */

#a-gaveta {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.507);
  position: fixed;
  display: none;
  float: left;
  z-index: 3;
}

#a-ajuste-gaveta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#a-gaveta:target {
  display: block;
  z-index: 3;
}

#a-gaveta:target ~ .box {
  display: block;
  z-index: 4;
}

#a-gaveta:target ~ .gaveta-popup {
  display: block;
}

.a-gaveta-popup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 100px;
  border: 2px solid black;
  border-radius: 15px;
  background: white;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.548);
}

#a-gaveta .a-gaveta-cont {
  text-align: center;
}

.modal-h1-text {
  margin-top: 0;
  -webkit-text-stroke: 1px black;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  color: #fff;
}

.modal-a-text {
  -webkit-text-stroke: 1px black;
  display: block;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  color: #fff;
  margin-top: 19.3px;
  border-radius: 21.8px;
  padding: 15px;
  background: rgb(121, 194, 38);
  border: 2px solid black;
  cursor: pointer;
}

@media (max-width: 980px) {
  /*  Globais celular  */

  .caixa-texto-confirmcao-input {
    min-width: 250px;
  }

  .box-topo {
    grid-template-columns: auto;
    width: 86%;
    padding: 15px;
  }

  .item-box-topo-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .item-box-topo-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .btn-repetir {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }

  .p-text {
    margin-right: 0%;
    font-size: 25px;
  }
  /*  Tela 1.html celular  */

  .tl1-box-2 {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }

  .tl1-figura-1 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    cursor: pointer;
  }

  .tl1-figura-2 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    cursor: pointer;
  }

  /*  Tela 2.html celular  */

  .tl2-box-2 {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }

  .tl2-figura-1 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(239 165 202);
  }

  .tl2-figura-2 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(249 205 141);
  }

  .tl2-figura-3 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(208 224 157);
  }

  .tl2-figura-4 {
    padding: 20px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(170 145 192);
  }

  /*  Tela 3.html celular  */

  .tl3-box-2 {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }

  .tl3-figura-1 {
    height: 120px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl3-figura-2 {
    height: 120px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  .tl3-figura-3 {
    height: 120px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl3-figura-4 {
    height: 120px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  /*  Tela 4.html celular  */

  .tl4-box-1 {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }

  .tl4-box-figuras {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 92%;
    height: auto;
  }

  .tl4-figura-lateral {
    height: 180px;
    border-radius: 15px;
    background: rgb(197 151 195);
  }

  .tl4-figura-2 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  .tl4-figura-3 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl4-figura-4 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl4-figura-5 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  /*  Tela 5.html celular  */

  .tl5-box-2 {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }

  .tl5-figura-1 {
    height: 180px;
    border: 0 solid;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl5-figura-2 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  .tl5-figura-3 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl5-figura-4 {
    height: 180px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  /*  Tela 6.html celular  */

  .tl6-box-2 {
    grid-gap: 15px;
    grid-template-columns: 100%;
    width: 86%;
    height: auto;
  }

  .tl6-box-3 {
    grid-column-gap: 10px;
    grid-template-columns: 46% 46%;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    margin: 0 auto;
  }

  .tl6-figura-1 {
    height: 250px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(255, 255, 255);
  }

  .tl6-figura-2 {
    grid-template-columns: 50px 50px;
    height: 140px;
    border: 7px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }

  .tl6-figura-3 {
    grid-template-columns: 25px 25px 25px 25px;
    grid-gap: 8px;
    border: 7px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }

  .tl6-figura-interior-1 {
    border-radius: 5px;
  }

  .tl6-figura-interior-2 {
    border-radius: 5px;
  }

  /* .box-quebra-cabeca {
        grid-template-columns: auto auto auto auto;
        width: 92%;
        height: 420px;
    }   */

  /*  Tela 7.html celular  */

  .tl7-box-2 {
    grid-template-columns: auto auto auto auto;
    width: 92%;
    height: 420px;
  }

  /*  Tela 8.html celular  */

  .tl8-box-2 {
    grid-template-columns: auto auto auto auto;
    width: 92%;
    height: 500px;
  }

  /*  Tela 9.html celular  */

  .tl9-box-2 {
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
    padding: 15px;
  }

  .tl9-box-3 {
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 12px;
    height: auto;
    padding: 15px;
  }

  .tl9-figura-1 {
    height: 180px;
  }

  .tl9-figura-2 {
    grid-row-start: 1;
    height: 180px;
  }

  /*  Modal celular  */

  .gaveta-popup {
    width: 86%;
    padding: 10px 0 20px;
  }
}
