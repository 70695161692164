.login {
  margin-bottom: 10px;
}

.login .box-password {
  margin: 0 auto;
  display: inline-block;
}

.login input[type='text'],
.login input[type='password'],
.login input[type='email'],
.login input[type='phone'] {
  display: block;
  margin: 0 auto 15px;
  padding: 12px;
  width: 90%;
  color: #010100;
  /* text-transform: uppercase; */
  border: 1px solid darkgray;
  border-radius: 6px;
}

.login input[type='text']::placeholder,
.login input[type='password']::placeholder {
  font-size: 1em;
  font-style: italic;
}

.login input[type='text']:focus,
.login input[type='password']:focus {
  outline: #008fee;
}

.btn-entrar:hover {
  background: #006db5;
}

.btn-entrar {
  margin: 0 auto;
  padding: 12px 50px 12px;
  font-size: 1em;
  font-weight: 500;
  line-height: 18px;
  background-color: #008fee;
  border-bottom: 4px solid #005089;
  border-left: 0px solid transparent;
  border-radius: 10px;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  color: #ffffff;
  font-family: inherit;
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

.btn-cadastrar:hover {
  background: #006db5;
}

.btn-cadastrar {
  margin: 0 auto;
  padding: 12px 50px 12px;
  font-size: 1em;
  font-weight: 500;
  line-height: 18px;
  background-color: #008fee;
  border-bottom: 4px solid #005089;
  border-left: 0px solid transparent;
  border-radius: 10px;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  color: #ffffff;
  font-family: inherit;
  /* max-width: 100%; */
  cursor: pointer;
  text-decoration: none;
  width: 25%;
  display: inline-block;
}