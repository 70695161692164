@media(max-width: 700px) {
  .aula {
    margin: 0;
    height: 300px;
      background-size: 100% !important;
    background-repeat: no-repeat !important; 
    z-index: 1;
    border: solid;
      border-radius: 15px;
      border-width: 7px;
  }

  .itens li .badge-border {
    margin: 0;
    height: 390px;
      background-size: 100% !important;
    width: 390px;
    background-repeat: no-repeat !important; 
    z-index: 1;
    border: solid;
      border-radius: 15px;
      border-width: 7px;
  }

  .seta-01, 
	.seta-02 {
		width: 60px;
		height: 64px;
		background-position: center;
    background-repeat: no-repeat;
    margin-top: -140px;
  }
  
  .atividades iframe {
    width: 100%;
    min-height: 250px;
  }

  .container-login {
    padding: 18px;
    height: 100%;
  }

  .gaveta-popup {
    height: 100%;
  }
}